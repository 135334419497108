import React, { useContext } from "react";
import white from "../Pictures/Gallery/white-lion.png";
import black from "../Pictures/Gallery/black-lion.png";
import MainCard from "./mainCard";
import { ThemeContext } from "../../ThemeProvider";

const Home = () => {
  const { isDarkTheme } = useContext(ThemeContext);

  return (
    <div className="home">
      <div className="center"></div>
      <MainCard
        img={isDarkTheme ? black : white} // Conditionally set the image based on theme
        title="Matteo Rosoli"
        emojis="Software Engineer & Project Manager"
      />
    </div>
  );
};

export default Home;
