import React, { Component } from "react";

class MainCard extends Component {
  render() {
    return (
      <div className="projectCards2">
        <div className="homeImageDiv">
          <img
            className="homeImage"
            src={this.props.img}
            alt="Image could not be displayed"
          />
        </div>
        <div className="projectsContainer">
          <h1 className="cardTitle">{this.props.title}</h1>
          <p>
            {this.props.emojis} <br />
            {this.props.text}
          </p>
          <p></p>
        </div>
      </div>
    );
  }
}

export default MainCard;
