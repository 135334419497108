import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../ThemeProvider";
import newLogo from "../Pictures/new-logo.svg";
import secondaryNewLogo from "../Pictures/secondary-new-logo.svg";
import line from "../Pictures/line.svg";
import secondaryLine from "../Pictures/secondary-line.svg";

const MyHeader = () => {
  const [hidden, setHidden] = useState(false);
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);

  const imageClick = () => {
    setHidden(!hidden);
  };

  const Phone = () => {
    return (
      <div>
        <div className="logoDiv phone">
          <img
            className="logo"
            src={isDarkTheme ? newLogo : secondaryNewLogo}
            alt="Image could not be displayed"
            onClick={() => imageClick()}
          />
        </div>
        {!hidden && (
          <div className="headerSplit phone">
            <div className="centerLine">
              <img
                className="line"
                src={isDarkTheme ? line : secondaryLine}
                alt="Image could not be displayed"
              />
            </div>
            <div className="headerText">
              <Link className="headerLink" to="/">
                Home
              </Link>
              <Link className="headerLink" to="/projects">
                Projects
              </Link>
              <Link className="headerLink" to="/imprint">
                Imprint
              </Link>
              <Link className="headerLink" to="/privacy">
                Privacy
              </Link>
              <Link className="headerLink" onClick={toggleTheme}>
                {isDarkTheme ? "Light Theme" : "Dark Theme"}
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="header">
      <Phone></Phone>
      <div className="logoDiv big">
        <img
          className="logo"
          src={isDarkTheme ? newLogo : secondaryNewLogo}
          alt="Image could not be displayed"
        />
      </div>
      <div className="headerSplit big">
        <div className="centerLine">
          <img
            className="line"
            src={isDarkTheme ? line : secondaryLine}
            alt="Image could not be displayed"
          />
        </div>
        <div className="headerText">
          <Link className="headerLink" to="/">
            Home
          </Link>
          <Link className="headerLink" to="/projects">
            Projects
          </Link>
          <Link className="headerLink" to="/imprint">
            Imprint
          </Link>
          <Link className="headerLink" to="/privacy">
            Privacy
          </Link>
          <Link className="headerLink" onClick={toggleTheme}>
            {isDarkTheme ? "Light Theme" : "Dark Theme"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyHeader;
