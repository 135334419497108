import React, { createContext, useState, useEffect } from "react";

// Create the context
export const ThemeContext = createContext();

// Create a provider component
export const ThemeProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(true);

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  useEffect(() => {
    if (isDarkTheme) {
      setDarkTheme();
    } else {
      setLightTheme();
    }
  }, [isDarkTheme]);

  const setDarkTheme = () => {
    document.documentElement.style.setProperty("--text-color", "white");
    document.documentElement.style.setProperty("--background-color", "black");
    document.documentElement.style.setProperty("--primary-color", "#59c3ed");
    document.documentElement.style.setProperty("--cards-background", "black");
  };

  const setLightTheme = () => {
    document.documentElement.style.setProperty("--text-color", "black");
    document.documentElement.style.setProperty("--background-color", "white");
    document.documentElement.style.setProperty("--primary-color", "#FBADE0");
    document.documentElement.style.setProperty("--cards-background", "#FFE8F7");
  };

  return (
    <ThemeContext.Provider value={{ isDarkTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
