import React, { useState } from "react";

const Imprint = () => {
  return (
    <div className="imprint">
      <div className="imprintText">
        <div className="contact">
          <br />
          <h2>Matteo Rosoli</h2> <br />
          E-Mail:{" "}
          <a href="mailto:office@matteorosoli.at">
            office@matteorosoli.at
          </a>{" "}
          <br />
          Sekundäre E-Mail:{" "}
          <a href="mailto:rosolimatteo@gmx.at">rosolimatteo@gmx.at</a> <br />
        </div>
        <div className="uhrheberrecht">
          <br />
          <h2>Inhalt der Website</h2> <br />
          Dies ist eine private Website die nur zur Information dient. Alle
          Rechte sind vorbehalten und es gilt das gesetzliche Urheberrecht. Eine
          Weiterverwendung und Reproduktion meiner Inhalte ist nicht gestattet.
          <br />
          Website von <b>Matteo Rosoli</b>.
          <br />
          Logo und Art von <b>Matteo Rosoli</b> und <b>Ian Memic</b>.
          <br />
          Websitedesign-, Cyber-Security-, Trainer-, Mobile- and Thesis-Picture
          in /projects were generated by DALLE.
          <br />
          <br />
          <h2>Urheberrecht</h2>
          Die durch den Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem österreichischen Urheberrecht. Die
          Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
          außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
          Zustimmung des jeweiligen Autors bzw. Erstellers.
          <br />
        </div>
      </div>
    </div>
  );
};

export default Imprint;
