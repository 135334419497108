import React from "react";
const p404 = () => {
  return (
    <div className="home">
      <h1>404 Error</h1>
      Ooops. Ich glaube da hat sich jemand verirrt.
    </div>
  );
};

export default p404;
