import React, { useState } from "react";
const Privacy = () => {
  return (
    <div className="imprint">
      <div className="imprintText">
        <div className="contact">
          <br />
          <h1>Datenschutz</h1>
          <p>
            <b>
              <u>
                Diese Website speichert und verarbeitet keine personenbezogenen
                Daten.
              </u>
            </b>
          </p>
          <h3>Cookies</h3>
          <p>Diese Website verwendet keine Cookies.</p>
          <h3>Links</h3>
          <p>
            {" "}
            Diese Seite verweist auf Websiten Dritter. Für dessen Inhalte
            übernehme ich keine Haftung. Letzter Check der Inhalte: Juni 2024.
          </p>
          <h3>Server Logging Files</h3>
          <p>Mein Server nimmt aus technischen Gründen folgende Daten auf:</p>
          +Browser <br />
          +Betriebssystem <br />
          +Referrer URL <br />
          +Datum und Uhrzeit Ihres Zugriffs <br />
          <p>
            IP Adressen werden von meinen Logging Files aufgrund einer
            technischen Maßnahme NICHT gespeichert.{" "}
            <a
              className="imprintLink"
              target="_blank"
              href="https://schneide.blog/2019/09/24/how-to-disable-ip-address-logging-for-apache-web-server-and-tomcat/"
            >
              Für mehr Information über meine technische Umsetzung, klicke hier!
            </a>
          </p>
          <h3>Google Fonts</h3>
          <p>
            Diese Website verwendet Google Fonts. Der verwendete Font ist Roboto
            Mono und lokal auf meinem Server gespeichert. Das heißt, keine
            IP-Adressen werden über meine Website an Google weitergegeben.{" "}
            <a
              className="imprintLink"
              target="_blank"
              href="https://developers.google.com/fonts"
            >
              Für mehr Information klicke hier!
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
