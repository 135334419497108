import React, { useState } from "react";
import projectsData from "./data/projects";
import Card2 from "./Card2";

const Projects = () => {
  const [filter, setFilter] = useState("all");
  const [expandedCardIndex, setExpandedCardIndex] = useState(null);

  const filteredProjects =
    filter === "all"
      ? projectsData.sort((a, b) => a.order - b.order)
      : projectsData
          .filter((project) =>
            filter === "Internship" || filter === "Job"
              ? project.type === "Internship" ||
                project.type === "Job" ||
                project.type === "Side Job"
              : filter === "AI"
              ? project.type === "AI" ||
                project.type === "Conference Talk" ||
                project.type === "Diploma Thesis"
              : project.type === filter
          )
          .sort((a, b) => a.order - b.order);

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    fontSize: "1.7vh",
  };

  const projectRowStyle = {
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  };

  const galleryOverDivStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  };

  const handleCardClick = (index) => {
    setExpandedCardIndex(expandedCardIndex === index ? null : index);
  };

  return (
    <div style={galleryOverDivStyle}>
      {/* <div style={buttonContainerStyle}>
        <div style={buttonContainerStyle}>
          {filter !== "all" && (
            <button className="buttonStyle" onClick={() => setFilter("all")}>
              All
            </button>
          )}
          {filter !== "Internship" && (
            <button
              className="buttonStyle"
              onClick={() => setFilter("Internship")}
            >
              Work
            </button>
          )}
          {filter !== "Project" && (
            <button
              className="buttonStyle"
              onClick={() => setFilter("Project")}
            >
              Projects
            </button>
          )}
          {filter !== "AI" && (
            <button className="buttonStyle" onClick={() => setFilter("AI")}>
              AI
            </button>
          )}
        </div>
      </div> */}

      <div style={projectRowStyle}>
        {filteredProjects.map((project, index) => (
          <Card2
            key={index}
            type={project.type}
            img={project.img}
            title={project.title}
            text={project.text}
            link={project.link}
            linkText={project.linkText}
            onClick={() => handleCardClick(index)}
            expanded={expandedCardIndex === index}
            disablePointerEvents={
              expandedCardIndex !== null && expandedCardIndex !== index
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
