import "./App.css";
import React, { useState } from "react";
import MyHeader from "./Components/Essentials/MyHeader";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Components/Pages/Home";
import Projects from "./Components/Pages/Projects";

import Privacy from "./Components/Essentials/Privacy";
import Imprint from "./Components/Essentials/Imprint";
import logo from "./Components/Pictures/logo-lines-transparent.svg";

import p404 from "./Components/Pages/404";

import { ThemeProvider } from "./ThemeProvider";

function App() {
  const [hidden, setHidden] = useState(true);
  function WebPage() {
    return (
      <ThemeProvider>
        <Router>
          <div className="homeSplit">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/Projects" component={Projects} />
              <Route path="/Privacy" component={Privacy} />
              <Route path="/Imprint" component={Imprint} />
              <Route path="*" status={404} component={p404} />
            </Switch>
            <MyHeader />
          </div>
        </Router>
      </ThemeProvider>
    );
  }
  window.addEventListener("mousedown", (event) => {
    setHidden(true);
  });
  function Logo() {
    return (
      <div className="mainLogoDiv">
        <img src={logo} className="mainLogo" alt="Logo couldn't load" />
      </div>
    );
  }
  return <div className="App">{hidden ? WebPage() : Logo()}</div>;
}

export default App;
