import React, { useState, useRef } from "react";

function Card2(props) {
  const [expanded, setExpanded] = useState(false);
  const cardRef = useRef(null);

  const handleCardClick = () => {
    if (!expanded) {
      const cardRect = cardRef.current.getBoundingClientRect();
      setExpanded(true);
      setOriginalPosition({ top: cardRect.top, left: cardRect.left });
    } else {
      setExpanded(false);
    }
  };

  const [originalPosition, setOriginalPosition] = useState({ top: 0, left: 0 });

  return (
    <div
      ref={cardRef}
      className={`projectCards ${expanded ? "expanded" : ""} ${
        props.disablePointerEvents ? "disablePointerEvents" : ""
      }`}
      onClick={() => {
        props.onClick();
        handleCardClick();
      }}
      style={
        expanded
          ? {
              top: `calc(50%)`,
              left: `calc(50%)`,
            }
          : null
      }
    >
      {!expanded && <h2 className="projectsH2">{props.type}</h2>}
      {expanded && <h3>{props.title}</h3>}
      <div className="projectsImageContainer">
        <img
          src={props.img}
          className="projectsImage"
          style={{
            maxHeight: "150px",
            maxWidth: "100%",
          }}
        />
      </div>
      <div className={`projectsContainer ${expanded ? "expanded" : ""}`}>
        {!expanded && <h3>{props.title}</h3>}

        {expanded && (
          <div>
            <p className="smallerText">{props.text}</p>
            {props.link && (
              <a target="_blank" href={props.link} rel="noopener noreferrer">
                <p className="cardsLinkText">{props.linkText}</p>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Card2;
