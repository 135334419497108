// projectsData.js
import verteidigung from "../../Pictures/projects-bpt-logo.webp";
import motifit from "../../Pictures/projects-motifit.png";
import mobile from "../../Pictures/mobile.webp";
import trainer from "../../Pictures/trainer.webp";
import thesis from "../../Pictures/thesis.webp";
import text from "../../Pictures/text-generation.svg";
import sudokutime from "../../Pictures/react.png";
import websiteDesign from "../../Pictures/website-design.webp";
import cio from "../../Pictures/projects-cio.png";
import alew from "../../Pictures/projects-alew-banner.png";
import node from "../../Pictures/projects-node.png";
import analyse from "../../Pictures/potential-analyse.svg";

const projectsData = [
  {
    type: "Internship",
    img: verteidigung,
    title: "Cyber Security & SysAdmin",
    order: 1,
    text: "As part of the 'Berufspraktische Tage' I got insight in how cyber security and system administration actually works.",
  },
  {
    type: "Project Lead",
    img: motifit,
    title: "React Native App",
    order: 4,
    text: "Leading a team of 4 developers during a 1 year long project as well as learning the crucial steps in cross-platform mobile development for the first time.",
  },
  {
    type: "Internship",
    img: mobile,
    title: "Mobile Dev & Scrum",
    order: 11,
    text: "Working in several Scrum Teams as a mobile developer & software tester additionally to getting real-life insights on Agile project management for the duration of a month.",
  },
  {
    type: "AI",
    img: text,
    title: "Article Generator",
    order: 14,
    text: "GPT2 based article generating LLM before ChatGPT was released. Finetuned on over six million articles from my cooperation partner. More at CIO Congress.",
  },
  {
    type: "Project Lead",
    img: sudokutime,
    title: "React WebApp",
    order: 2,
    text: "Leading a team of 4 developers for the first time. Getting into web & backend development as well as server management.",
  },
  {
    type: "Internship",
    img: websiteDesign,
    title: "Website Design",
    order: 3,
    text: "Internship at a small Austrian company. Mainly working on website development, data base management and design.",
  },
  {
    type: "Project",
    img: mobile,
    title: "Flutter App",
    order: 6,
    text: "For the duration of a whole year. Leading again a team of 4 developers to program a mobile app in Flutter. Learning to communicate with customers and tailoring products to customer's need.",
  },
  {
    type: "Job",
    img: trainer,
    title: "Trainer",
    order: 8,
    text: "Used to teach beginner classes for Kickboxing and BJJ.",
  },
  {
    type: "Conference Talk",
    img: cio,
    title: "CIO Congress Presentation",
    order: 13,
    text: "Here I presented the working prototype of my diploma thesis in front of all the big players in ICT in Austria :D",
  },
  {
    type: "Project",
    img: alew,
    title: "Unity Game",
    order: 7,
    text: "External Project manager and Scrum master.",
  },
  {
    type: "Project",
    img: node,
    title: "Node.js Server",
    order: 10,
    text: "Programming and maintaining a node.js server for backend handling.",
  },
  {
    type: "Diploma Thesis",
    img: thesis,
    title: "NLP & Data Preprocessing",
    order: 15,
    text: "Part of the project regarding the potential analysis and the article generation I wrote my diploma thesis about NLP etc. More at CIO Kongress!",
  },
  {
    type: "AI",
    img: analyse,
    title: "Potential Analysis",
    order: 13,
    text: "An AI forecast looking to predict the reach and other key metrics of articles. More at CIO Kongress :)",
  },
  {
    type: "And so much more!",
    title: "...",
    order: 16,
    text: "For information regarding my current projects, shoot me a message",
  },
];

export default projectsData;
